import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/media'

const LegalBodyStyles = styled.div`
  width: 100%;
  background: var(--offWhite);
  padding: 2rem 0;
  z-index: 10;
  .media {
    margin: 2rem 0;
    li {
      margin: 1rem 0;
    }
  }
  div {
    position: relative;
    max-width: 1000px;
    width: 90%;
    margin: var(--auto);
    color: var(--darkGrey);
    h1 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
    h5 {
      margin-top: 1.5rem;
      font-size: 1.25rem;
    }
    p {
      margin: 1rem 0;
    }
    p,
    li {
      font-size: 16px;
    }
    strong {
      font-weight: 700;
    }
    ol > li {
      margin-top: 1rem;
    }
    ul,
    ol {
      padding-left: 20px;
      @media ${media.md} {
        padding-left: 30px;
      }
      li {
        strong {
          font-weight: 500;
        }
      }
    }
  }
  section {
    background-color: var(--teal);
    padding: 1rem;
    > h5 {
      margin-top: 0;
    }
    p {
      margin: 0.25rem 0;
    }
  }
`

const LegalBody = ({ children }) => (
  <LegalBodyStyles>
    <div>{children}</div>
  </LegalBodyStyles>
)

export default LegalBody
